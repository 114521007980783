import React from 'react';
import { Link } from 'gatsby';

// components
import Layout from '../components/layout';

const ServicePage = () => {
  return(
    <Layout>
      <div className="container__box">
        <h1><span class="heading__naples">Serwis</span> komputerowy</h1>
        <ul>
          <li>Serwis laptopów</li>
          <li>Serwis komputerów stacjonarnych</li>
        </ul>
      </div>
      <div className="container__graybox">
        <h2>Twój laptop lub komputer wymaga naprawy?</h2>
        <p>Specjalizujemy się w naprawie sprzętu komputerowego</p>
        <p>Zadzwoń do nas i przynieś uszkodzony sprzęt! Nasze usługi są realizowane od ręki. Diagnoza przeprowadzana za darmo. Wycena w większości przypadków natychmiastowa.</p>
      </div>
      <div className="container_box">
        <h2>Studenckie ceny</h2>
        <p><Link to="/contact">Zapytaj o wycenę i przekonaj się!</Link></p>
        <p>100% zadowolonych klientów!</p>
      </div>
      <div className="container__graybox">
        <h2>Przykładowe usługi</h2>
        <ul>
          <li>Wymiana matrycy</li>
          <li>Wymiana klawiatury</li>
          <li>Naprawa płyt głównych</li>
          <li>Instalacja systemu operacyjnego z zachowaniem danych</li>
          <li>Konfiguracja bios</li>
          <li>Wymiana uszkodzonych dysków</li>
          <li>Wymiana napędu optycznego</li>
          <li>Wymiana podzespołów</li>
          <li>Wymiana gniazda zasilania</li>
          <li>Czyszczenie układów chłodzenia</li>
        </ul>
      </div>
    </Layout>
  );
}

export default ServicePage;